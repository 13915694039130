import {
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import { Link, navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import {
  authenticate,
  createAccount,
  updateUserDetails,
} from "../../services/moocfi"
import { capitalizeFirstLetter } from "../../util/strings"
import withSimpleErrorBoundary from "../../util/withSimpleErrorBoundary"
import { SecondaryButton } from "../Buttons"
import courseSettings from "../../../course-settings"
import LoginStateContext from "../../contexes/LoginStateContext"

const ErrorMessage = styled.p`
  font-family: "Lato" !important;
  color: #e53935;
  font-weight: bold;
`

const Row = styled.div`
  margin-bottom: 1.5rem;
`

const Form = styled.form``

const InfoBox = styled.div`
  margin-bottom: 3rem;
  font-size: 1rem;
  line-height: 1.8rem;
`

const FormContainer = styled.div`
  margin: 2rem auto;
  text-align: center;
  height: 100%;
  max-width: 600px;

  h1 {
    font-family: "Josefin Sans", sans-serif;
  }
`

const StyledLink = styled(Link)`
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  color: #333;
  opacity: 0.8;
`

const COURSE_VARIANT_KEYS = {
  Laurea: "LIKOMOOC",
  HAMK: "LITAMOOC",
}

const CreateAccountForm = (props) => {
  const [state, setState] = useState({
    email: undefined,
    password: undefined,
    password_confirmation: undefined,
    use_course_variant: undefined,
    course_variant: null,
    course_variants: courseSettings.courseVariants,
    student_number: undefined,
    submitting: false,
    error: false,
    errorObj: {},
    validatePassword: false,
    validateEmail: false,
    canSubmit: true,
    triedSubmitting: true,
  })

  const context = useContext(LoginStateContext)

  const onClick = async (e) => {
    e.preventDefault()
    if (
      state.course_variant &&
      state.course_key !== COURSE_VARIANT_KEYS[state.course_variant]
    ) {
      setState({
        ...state,
        error: true,
        errorObj: {
          message: props.t("invalidKey"),
        },
      })
      setTimeout(() => {
        setState({ ...state, error: false, errorObj: {} })
      }, 5000)
      return
    }

    setState({ ...state, submitting: true, triedSubmitting: true })
    if (!validate()) {
      setState({ ...state, canSubmit: false, submitting: false })
      return
    }
    try {
      const res = await createAccount({
        email: state.email,
        password: state.password,
        password_confirmation: state.password_confirmation,
        extraFields: {
          use_course_variant: state.use_course_variant,
          course_variant: state.course_variant,
        },
        userField: { organizational_id: state.student_number },
      })
      await authenticate({
        username: state.email,
        password: state.password,
      })

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", "CompleteRegistration", {})
        }
      }
      props.onComplete()
    } catch (error) {
      try {
        let message = ""
        Object.entries(error).forEach((o) => {
          const key = o[0]
          const value = o[1]
          value.forEach((msg) => {
            let newMessage = capitalizeFirstLetter(
              `${key.replace(/_/g, " ")} ${msg}.`,
            )
            if (newMessage === "Email has already been taken.") {
              newMessage = props.t("emailInUse")
            }
            message = `${message} ${newMessage}`
          })
        })

        if (message === "") {
          message = props.t("problemCreatingAccount") + JSON.stringify(error)
        }
        setState({
          ...state,
          error: message,
          submitting: false,
          errorObj: error,
        })
      } catch (_error2) {
        setState({ ...state, error: JSON.stringify(error), submitting: false })
      }

      setState({ ...state, submitting: false })
    }
  }

  const handleInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    setState({ ...state, [name]: value })
  }

  const validate = () => {
    let newState = {
      error: "",
      errorObj: {},
    }
    const {
      email,
      password,
      password_confirmation,
      validatePassword,
      validateEmail,
      triedSubmitting,
    } = state
    if (email && validateEmail) {
      if (email.indexOf("@") === -1) {
        newState.error += props.t("noAt")
        newState.errorObj.email = props.t("noAt")
      }
      if (email && email.indexOf(".") === -1) {
        newState.error += props.t("noAt")
        newState.errorObj.email = props.t("noAt")
      }
    }

    if (password && password_confirmation && validatePassword) {
      if (password !== password_confirmation) {
        newState.error += props.t("passwordsNoMatch")
        newState.errorObj.password = props.t("passwordsNoMatch")
        newState.errorObj.password_confirmation = props.t("passwordsNoMatch")
      }
    }

    if (newState.error === "") {
      newState.error = false
      newState.canSubmit = true
    }

    if (!email || !password || !password_confirmation) {
      if (triedSubmitting) {
        newState.canSubmit = false
      }
      return false
    }
    setState({ ...state, error: newState.error, errorObj: newState.errorObj })
    return !newState.error
  }

  const handleCourseVariantCheckbox = (e) => {
    setState({
      ...state,
      use_course_variant: e.target.checked,
      course_variant: "",
    })
  }

  const handleFocus = (e) => {
    const name = e.target.name
    setState({ ...state, focused: name })
  }

  const handleUnFocus = () => {
    setState({ ...state, focused: null })
  }

  const handleEmailOnBlur = () => {
    setState({ ...state, validateEmail: true })
  }

  if (context.loggedIn) {
    navigate("/")
    return <div>Redirecting...</div>
  }
  const isCourseVariantTrue = state.course_variant ? true : false
  return (
    <FormContainer>
      <h1>{props.t("createAccount")}</h1>
      <Form onSubmit={validate}>
        <InfoBox>
          {props.t("courseUses")}{" "}
          <a href="https://mooc.fi" target="_blank" rel="noopener noreferrer">
            mooc.fi
          </a>{" "}
          {props.t("courseUses2")}
        </InfoBox>

        <Row>
          <TextField
            variant="outlined"
            type="email"
            name="email"
            autoComplete="email"
            label={props.t("email")}
            error={state.errorObj.email}
            fullWidth
            value={state.email}
            onChange={handleInput}
            onBlur={handleEmailOnBlur}
          />
        </Row>
        <Row>
          <TextField
            variant="outlined"
            type={state.showPassword ? "text" : "password"}
            label={props.t("password")}
            name="password"
            error={state.errorObj.password}
            fullWidth
            value={state.password}
            onChange={handleInput}
          />
        </Row>
        <Row>
          <TextField
            variant="outlined"
            type={state.showPassword ? "text" : "password"}
            label={props.t("passwordAgain")}
            name="password_confirmation"
            error={state.errorObj.password_confirmation}
            fullWidth
            value={state.password_confirmation}
            onChange={handleInput}
            onBlur={() => {
              setState({ ...state, validatePassword: true })
            }}
          />
        </Row>
        {state.course_variants.length === 0 ? null : (
          <div>
            <h2>{props.t("courseInfo")}</h2>
            <Row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.use_course_variant}
                    onChange={handleCourseVariantCheckbox}
                    name="use_course_variant"
                    value="1"
                  />
                }
                disabled={state.course_variants.length === 0}
                label={props.t("useCourseVariantLabel")}
              />
            </Row>

            <Row>
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="select-course"
                  shrink={
                    isCourseVariantTrue || state.focused === "course_variant"
                  }
                >
                  {props.t("courseVariant")}
                </InputLabel>
                <Select
                  key={state.use_course_variant}
                  disabled={!state.use_course_variant}
                  labelId="select-course"
                  label={props.t("courseVariant")}
                  name="course_variant"
                  value={state.course_variant || ""}
                  onChange={handleInput}
                  onFocus={handleFocus}
                  onBlur={handleUnFocus}
                >
                  <MenuItem value="" disabled>
                    {props.t("chooseCourse")}
                  </MenuItem>
                  {state.course_variants.map((x) => {
                    const key = x.organization
                    return (
                      <MenuItem value={key} key={key}>
                        {x.title}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Row>
            {state.course_variant && (
              <>
                <Row>
                  <TextField
                    variant="outlined"
                    type="text"
                    label={`${props.t("courseKey")}`}
                    name="course_key"
                    InputLabelProps={{
                      shrink:
                        state.course_key || state.focused === "course_key",
                    }}
                    fullWidth
                    value={state.course_key}
                    onChange={handleInput}
                    helperText={state.course_variant.fullName}
                    onFocus={handleFocus}
                    onBlur={handleUnFocus}
                  />
                  {state.error && state.errorObj.message && (
                    <ErrorMessage>{props.t("invalidKey")}</ErrorMessage>
                  )}
                </Row>
              </>
            )}
          </div>
        )}

        <Row>
          <SecondaryButton
            onClick={onClick}
            disabled={state.submitting || !state.canSubmit}
            variant="contained"
            color="black"
            hover="#4EC4A1"
            bg="#52E3C2"
            active="#4CBF9D"
            fullWidth
            type="submit"
          >
            {props.t("create")}
          </SecondaryButton>
        </Row>
      </Form>
      <Row>
        <StyledLink to="/sign-in">{props.t("alreadyHaveAccount")}</StyledLink>
      </Row>
      {state.error && (
        <InfoBox>
          <b>
            {props.t("error")} {state.error}
          </b>
        </InfoBox>
      )}
    </FormContainer>
  )
}

export default withTranslation("user")(
  withSimpleErrorBoundary(CreateAccountForm),
)
